import React, { FunctionComponent, useEffect, useRef } from "react";
import { AuthClient } from "../utils/auth";

type Props = {
    auth: AuthClient | null,
};

const LoginPage: FunctionComponent<Props> = (props) => {

    const {
        auth
    } = props;

    const loginButtonElement = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (auth != null && loginButtonElement.current != null) {
            auth.renderButton(loginButtonElement.current);
        }
    }, [ auth ]);

    return (
        <div data-testid="login-page">
            <div className="signin-message">
                Please sign in.
                <div id="login-button-wrapper" ref={loginButtonElement} />
            </div>
        </div>
    );
}

export default LoginPage;
