type Handler = (params: any) => any;

export default class Events {
    private readonly _map: { [key: string]: Handler[] };
    constructor() {
        this._map = {};
    }
    on(eventName: string, fn: Handler) {
        let listeners = this._map[eventName];
        if (listeners == null) {
            listeners = [];
            this._map[eventName] = listeners;
        }
        listeners.push(fn);
        return () => {
            listeners = listeners.filter(x => x !== fn);
            this._map[eventName] = listeners;
        };
    }
    trigger(eventName: string, params: any = null) {
        const listeners = this._map[eventName];
        if (listeners != null) {
            for (const listener of listeners) {
                listener(params);
            }
        }
    }
}
