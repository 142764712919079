import Events from "./events";

type EventDefs = {
    'change': { value: boolean },
};

export abstract class AuthClient {
    private _events: Events;
    private _clientId: string;
    private _isLoggedIn: boolean | null = null;

    protected constructor(clientId: string) {
        this._clientId = clientId;
        this._events = new Events();
    }

    on<K extends keyof EventDefs>(eventName: K, args: (param:EventDefs[K]) => any) {
        this._events.on(eventName, args);
    }

    setResult(value: boolean) {
        if (this._isLoggedIn !== value) {
            this._isLoggedIn = value;
            this._events.trigger('change', { value });
        }
    }

    isLoggedIn() {
        return this._isLoggedIn;
    }

    getClientId() {
        return this._clientId;
    }

    abstract renderButton(el: HTMLElement): void;
}

export class LocalAuthClient extends AuthClient {

    protected constructor(clientId: string) {
        super(clientId);
    }

    static _promise: Promise<AuthClient> | null = null;

    static init(clientId: string, fn: (auth: AuthClient) => void): Promise<AuthClient> {

        if (this._promise != null) {
            throw '?!';
        }

        this._promise = new Promise<AuthClient>(resolve => {

            const client = new LocalAuthClient(clientId);
            fn(client);
            client.setResult(true);
            resolve(client);

        });

        return this._promise;

    }

    renderButton(el: HTMLElement) {

    }
}

declare global {
    interface Window {
        googleAuth_onPlatformLoaded: () => void;
        gapi: Gapi;
    }
}

export interface Gapi {
    load(moduleName: string, cb: () => void): void;
    auth2: GapiAuth2;
    signin2: GapiSignin2;
}
export interface GapiAuth2 {
    init(params: {client_id: string}): Promise<void>;
    getAuthInstance(): GapiAuth2Instance;
}
export interface GapiAuth2Instance {
    isSignedIn: GapiAuth2InstanceValue<boolean>;
    currentUser: GapiAuth2InstanceValue<GapiUserInstance | null>;
    signOut(): void;
}
export interface GapiUserInstance {
    getBasicProfile: () => GapiProfile;
}
export interface GapiProfile {
    getEmail: () => string;
}
export interface GapiAuth2InstanceValue<T> {
    get(): T;
}
export interface GapiSignin2RenderParams {
    onsuccess: () => void;
}
export interface GapiSignin2 {
    render(elId: string, params: GapiSignin2RenderParams): void;
}

export class GoogleAuthClient extends AuthClient {

    protected constructor(clientId: string) {
        super(clientId);
    }

    static _promise: Promise<AuthClient> | null = null;

    static init(clientId: string, fn: (auth: AuthClient) => void): Promise<AuthClient> {

        if (this._promise != null) {
            throw '?!';
        }

        const promise = new Promise<GoogleAuthClient>(resolve => {

            window.googleAuth_onPlatformLoaded = () => {
                window.gapi.load('auth2', () => {
                    window.gapi.auth2.init({
                        client_id: clientId,
                    }).then(() => {
                        const auth = new GoogleAuthClient(clientId);
                        fn(auth);
                        resolve(auth);
                    });
                });
            };

            const gapiScript = document.createElement('script');
            gapiScript.src = 'https://apis.google.com/js/platform.js?onload=googleAuth_onPlatformLoaded';
            document.body.appendChild(gapiScript);

        });

        this._promise = promise.then(client => {
            client.checkLogin();
            return client;
        });

        return this._promise;

    }

    checkLogin() {
        const result = this._isAuthenticated();
        this.setResult(result);
    }

    _isAuthenticated() {
        const auth2 = window.gapi.auth2.getAuthInstance();
        if (!auth2.isSignedIn.get()) {
            return false;
        }
        const currentUser = auth2.currentUser.get();
        if (currentUser == null) {
            return false;
        }
        const profile = currentUser.getBasicProfile();
        return this._authProfile(auth2, profile);
    }

    _authProfile( auth2: GapiAuth2Instance, profile: GapiProfile ) {
        // Make sure we are a Jazel email
        const email = profile.getEmail();
        if (email.endsWith('@jazel.com') || email.endsWith('@10thdegree.com')) {
            return true;
        } else {
            auth2.signOut();
            return false;
        }
    }

    renderButton(el: HTMLElement) {
        window.gapi.signin2.render(el.id, {
            onsuccess: () => {
                this.checkLogin();
            }
        });
    }
}

export async function initAuth(clientId: string, fn: (auth: AuthClient) => void) {

    let authClass;
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
        authClass = LocalAuthClient;
    } else {
        authClass = GoogleAuthClient;
    }
    return await authClass.init(clientId, fn);

}
