import { getS3Client } from "./aws";
import { packages_bucketName, packages_bucketRegion, packages_keyPrefix } from "../constants";
import { ListObjectsV2Command, ListObjectsV2CommandInput } from "@aws-sdk/client-s3";

export type PackageItem = {
    key: string,
    label: string,
};

export async function listPackages() {

    const s3Client = getS3Client(packages_bucketRegion);

    const input: ListObjectsV2CommandInput = {
        Bucket: packages_bucketName,
        Prefix: packages_keyPrefix,
        Delimiter: '/',
    };

    const command = new ListObjectsV2Command(input);

    try {
        const output = await s3Client.send(command);

        const list: PackageItem[] = [];

        if (output.Contents != null) {
            for (const content of output.Contents) {
                if (content.Key == null) {
                    continue;
                }
                let itemName = content.Key.slice( packages_keyPrefix.length );

                let periodPos = itemName.lastIndexOf( '.' );
                itemName = itemName.slice( 0, periodPos );

                if (itemName === '') {
                    continue;
                }

                list.push({
                    key: content.Key,
                    label: itemName,
                });
            }
        }

        return list;
    } catch(ex) {
        return null;
    }
}
