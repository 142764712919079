import { InvokeCommand, InvokeCommandInput } from "@aws-sdk/client-lambda";
import { getLambdaClient } from "./aws";
import { Message, MessageLevel } from "../components/MessagesWindow";
import { c3_lambdaRegion } from "../constants";

export type SubmitTaskToC3Params = {
    juid: string,
    signature: string,
    siteBase: string,
    siteRoot: string,
    siteDomain: string,
    siteName: string,
    siteType: string,
    autoDealershipName?: string,
    autoAccountId?: string,
    fdPaCode?: string,
    siteTemplate?: string,
};

export type SubmitTaskToC3Response = {
    isSuccess: boolean,
    messages: Message[],
};

export function c3MessageToMessage(message: { level: number, message: string }): Message {

    let levelString: MessageLevel;

    switch(message.level) {
        case 3:
            levelString = 'error';
            break;
        case 2:
            levelString = 'warn';
            break;
        case 1:
            levelString = 'info';
            break;
        default:
            levelString = 'debug';
    }

    return {
        level: levelString,
        messageHtml: message.message,
    };

}

export async function submitTaskToC3(params: SubmitTaskToC3Params): Promise<SubmitTaskToC3Response> {

    const payloadArray = (new TextEncoder()).encode(JSON.stringify(params));

    const invokeCommandInput: InvokeCommandInput = {
        FunctionName: "c3-lambda-CreateSiteAndDb",
        Payload: payloadArray,
    };

    const command = new InvokeCommand(invokeCommandInput);

    const lambdaClient = getLambdaClient(c3_lambdaRegion);

    try {
        const invokeCommandOutput = await lambdaClient.send(command);

        const responsePayloadArray = invokeCommandOutput.Payload;
        const responsePayload = JSON.parse( (new TextDecoder()).decode(responsePayloadArray) );

        return {
            isSuccess: responsePayload.isSuccess,
            messages: responsePayload.body.messages.map(c3MessageToMessage),
        };

    } catch(ex) {

        const messages: Message[] = [];

        messages.push({
            level: 'error',
            messageHtml: ex.toString(),
        });

        if (ex.stack != null && ex.stack !== '') {
            for(const line of ex.stack.split('\n')) {
                messages.push({
                    level: 'error',
                    messageHtml: line,
                });
            }
        }

        return {
            isSuccess: false,
            messages,
        };

    }

}
