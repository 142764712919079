import { FunctionComponent, useContext, useEffect, useState } from "react";
import SiteDetailsForm, { FormState } from "../components/SiteDetailsForm";
import MessagesWindow, { MessagesContext } from "../components/MessagesWindow";
import { submitTaskToC3, SubmitTaskToC3Params, SubmitTaskToC3Response } from "../utils/c3";
import { useMounted } from "../utils/testTools";
import { DropDownItems } from "../components/DropDownField";

type MainPageProps = {
    packagesList?: DropDownItems,
};

const MainPage: FunctionComponent<MainPageProps> = (props) => {

    const siteTypes = [
        {
            label: 'Standard',
            items: [
                {
                    key: 'standard',
                    value: 'Standard',
                },
            ],
        },
        {
            label: 'Automotive',
            items: [
                {
                    key: 'auto',
                    value: 'Generic Automotive',
                },
                {
                    key: 'auto-rv',
                    value: 'RV',
                },
                {
                    key: 'auto-forddirect',
                    value: 'FordDirect',
                },
            ],
        },
    ];

    const { packagesList } = props;

    const mounted = useMounted();

    const messages = useContext(MessagesContext);

    const [ startupTasksReady, setStartupTasksReady ] = useState(false);
    useEffect(() => {
        if (packagesList == null) {
            return;
        }
        if (!startupTasksReady) {
            messages.info('C3 2.0 Ready.');
            setStartupTasksReady(true);
        }
    }, [ packagesList ])

    const [ submitting, setSubmitting ] = useState(false);

    function handleSubmit(values: FormState) {
        async function doSubmitAsync() {

            let result: SubmitTaskToC3Response;
            try {
                setSubmitting( true );
                const signature = values.signatureInput + '-m2en';
                const c3params: SubmitTaskToC3Params = {
                    siteType: values.siteType,
                    juid: values.juid,
                    signature,
                    siteName: values.siteName,

                    autoAccountId: values.autoAccountId,
                    autoDealershipName: values.autoDealershipName,
                    fdPaCode: values.fdPaCode,
                    siteBase: 'm2enbase-0.0.1',
                    siteDomain: signature + '.a5.stag.jazelc.com',
                    siteRoot: 'm2en-rc',
                    siteTemplate: values.siteTemplate ?? '',
                };

                let message = "Build Site:\n" +
                    "Type: " + c3params.siteType + "\n" +
                    "JUID: " + c3params.juid + "\n" +
                    "Signature: " + c3params.signature + "\n" +
                    "Base: " + c3params.siteBase + "\n" +
                    "Root: " + c3params.siteRoot + "\n" +
                    "Domain: " + c3params.siteDomain + "\n" +
                    "Name: " + c3params.siteName + "\n";
                if (c3params.autoDealershipName != null) {
                    message += "Auto Dealership Name: " + c3params.autoDealershipName + "\n";
                }
                if (c3params.autoAccountId != null) {
                    message += "Auto Account ID: " + c3params.autoAccountId + "\n";
                }
                if (c3params.fdPaCode != null) {
                    message += "FordDirect PA Code: " + c3params.fdPaCode + "\n";
                }
                if (c3params.siteTemplate != null) {
                    const template = packagesList != null ? packagesList.find(item => item.key === c3params.siteTemplate ) : null;
                    const templateName = template != null ? template.value : 'Unknown';
                    message += "Site Template: " + templateName + "\n";
                }
                message += "\n" +
                    "Really create site?";

                if (!window.confirm(message)) {
                    return;
                }

                result = await submitTaskToC3(c3params);
            } finally {
                if (mounted.current) {
                    setSubmitting( false );
                }
            }

            if (result != null && Array.isArray(result.messages)) {
                for (const message of result.messages) {
                    messages.addMessage(message.level, message.messageHtml);
                }
            }
        }
        doSubmitAsync();
    }

    const goButtonDisabled = !startupTasksReady || submitting;

    return (
        <div data-testid="main-page">
            <SiteDetailsForm siteTypes={siteTypes}
                             packagesList={packagesList ?? []}
                             onSubmit={handleSubmit}
                             goButtonDisabled={goButtonDisabled}
            />
            <MessagesWindow />
        </div>
    );
};

export default MainPage;
