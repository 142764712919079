import styled from "styled-components";
import { FunctionComponent, MouseEvent, useState } from "react";
import DropDownField, { DropDownItemGroups, DropDownItems } from "./DropDownField";
import TextInputField from "./TextInputField";

const SiteDetailsFormRoot = styled.div`
`;

const SiteDetailsFormForm = styled.form`
    padding: 1rem 0.5rem;
    font-size: 1.2rem;
    .field {
        padding: 0.5rem 0;
    }
    input,
    select {
        font-size: 1.2rem;
        padding: 0.2rem;
    }
    button.submit {
        font-size: 1.2rem;
        padding: 0.2rem 2rem;
        border-radius: 0.25rem;
        cursor: pointer;
    }
    .full {
        width: 100%;
    }
    .half {
        width: 50%;
    }
    .quarter {
        width: 25%;
    }
`;

export type FormState = {
    juid: string,
    signatureInput: string,
    siteName: string,
    siteType: string,
    siteTemplate: string | null,
    autoDealershipName: string,
    autoAccountId: string,
    fdPaCode: string,
};

export type SiteDetailsFormProps = {
    goButtonDisabled?: boolean,
    siteTypes: DropDownItemGroups,
    packagesList: DropDownItems,
    initialState?: FormState,
    onSubmit?: (values: FormState) => void,
};

const defaultInitialState = {
    siteType: 'standard',
    juid: '',
    signatureInput: '',
    siteName: '',
    siteTemplate: null,
    autoDealershipName: '',
    autoAccountId: '',
    fdPaCode: '',
};

const SiteDetailsForm: FunctionComponent<SiteDetailsFormProps> = (props) => {

    const { goButtonDisabled = false, siteTypes, packagesList, initialState = defaultInitialState, onSubmit } = props;

    const [ formState, setFormState ] = useState<FormState>(initialState);

    function handleGoClick(e: MouseEvent) {
        e.preventDefault();
        if (onSubmit != null) {
            onSubmit(formState);
        }
    }

    const {
        siteType,
        juid,
        signatureInput,
        siteName,
        siteTemplate,
        autoDealershipName,
        autoAccountId,
        fdPaCode,
    } = formState;

    function handleBasicFieldUpdate<T extends keyof FormState>(fieldName: T, value: FormState[T]) {
        const newFormState = Object.assign({}, formState, {
            [fieldName]: value,
        });

        setFormState(newFormState);
    }

    const siteDomain = signatureInput !== '' ? signatureInput + '-m2en.a5.stag.jazelc.com' : '';

    return (
        <SiteDetailsFormRoot>
            <SiteDetailsFormForm data-testid='site-details-form'>

                <DropDownField label='Site Type:'
                               noneLabel={null}
                               groupedItems={siteTypes}
                               value={siteType}
                               onUpdate={(value) => {
                                   if (value != null && value !== siteType) {
                                       handleBasicFieldUpdate('siteType', value);
                                   }
                               }}
                />
                <TextInputField label='JUID:'
                                value={juid}
                                onUpdate={(value) => {
                                    if (value !== juid) {
                                        handleBasicFieldUpdate('juid', value);
                                    }
                                }}
                                inputClass='half'
                />
                <TextInputField label='Signature:'
                                value={signatureInput}
                                onUpdate={(value) => {
                                    if (value !== signatureInput) {
                                        handleBasicFieldUpdate('signatureInput', value);
                                    }
                                }}
                                suffix='-m2en'
                                inputClass='half'
                />
                <TextInputField label='Site Domain:'
                                disabled
                                value={siteDomain}
                                inputClass='full'
                />
                <TextInputField label='Site Name:'
                                value={siteName}
                                onUpdate={(value) => {
                                    if (value !== siteName) {
                                        handleBasicFieldUpdate('siteName', value);
                                    }
                                }}
                                inputClass='full'
                />

                <DropDownField label='Template:'
                               noneLabel='None'
                               items={packagesList}
                               value={siteTemplate}
                               onUpdate={(value) => {
                                   if (value != null && value !== siteType) {
                                       handleBasicFieldUpdate('siteTemplate', value);
                                   }
                               }}
                />

                <TextInputField label='Dealership Name:'
                                value={autoDealershipName}
                                onUpdate={(value) => {
                                    if (value !== autoDealershipName) {
                                        handleBasicFieldUpdate('autoDealershipName', value);
                                    }
                                }}
                                inputClass='full'
                />

                <TextInputField label='Auto5 Account ID:'
                                value={autoAccountId}
                                onUpdate={(value) => {
                                    if (value !== autoAccountId) {
                                        handleBasicFieldUpdate('autoAccountId', value);
                                    }
                                }}
                                inputClass='quarter'
                />

                <TextInputField label='FordDirect PACode:'
                                value={fdPaCode}
                                onUpdate={(value) => {
                                    if (value !== fdPaCode) {
                                        handleBasicFieldUpdate('fdPaCode', value);
                                    }
                                }}
                                inputClass='quarter'
                />


                <div className='field'>
                    <button onClick={handleGoClick}
                            disabled={goButtonDisabled}
                            className='submit'
                    >
                        Go!
                    </button>
                </div>

            </SiteDetailsFormForm>
        </SiteDetailsFormRoot>
    );
};

export default SiteDetailsForm;
