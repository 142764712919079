import { LambdaClient } from "@aws-sdk/client-lambda";
import { S3Client } from "@aws-sdk/client-s3";
import { aws_accessKeyId, aws_secretAccessKey } from "../constants";

let _lambdaClients: { [region: string]: LambdaClient } = {};
export function getLambdaClient(region: string) {
    if (region in _lambdaClients) {
        return _lambdaClients[region];
    }
    _lambdaClients[region] = new LambdaClient({
        apiVersion: '2015-03-31',
        region: region,
        credentials: {
            accessKeyId: aws_accessKeyId,
            secretAccessKey: aws_secretAccessKey,
        },
    });
    return _lambdaClients[region];
}

let _s3Clients: { [region: string]: S3Client } = {};
export function getS3Client(region: string): S3Client {
    if (region in _s3Clients) {
        return _s3Clients[region];
    }
    _s3Clients[region] = new S3Client({
        apiVersion: '2006-03-01',
        region: region,
        credentials: {
            accessKeyId: aws_accessKeyId,
            secretAccessKey: aws_secretAccessKey,
        },
    });
    return _s3Clients[region];
}
