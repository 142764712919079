import { ChangeEvent, FunctionComponent, useState } from "react";

export type DropDownItem = {
    key: string,
    value: string,
};
export type DropDownItems = DropDownItem[];

export type DropDownItemGroup = {
    label: string,
    items: DropDownItems,
};
export type DropDownItemGroups = DropDownItemGroup[];

type DropDownFieldProps = {
    id?: string,
    name?: string,
    label?: string,
    value?: string | null,
    emptyValue?: string,
    items?: DropDownItems,
    groupedItems?: DropDownItemGroups,
    noneLabel?: string | null,
    inputClass?: string,
    onUpdate?: (value: string | null) => void,
};

const DropDownField: FunctionComponent<DropDownFieldProps> = (props) => {

    const {
        id,
        name,
        label,
        value = '',
        emptyValue = '...',
        noneLabel = 'None',
        inputClass,
        items,
        groupedItems,
        onUpdate,
    } = props;

    const [ currentValue, setCurrentValue ] = useState<string | null>(value);

    function changeHandler(e: ChangeEvent<HTMLSelectElement>) {
        const updatedValue = e.currentTarget.value;
        setCurrentValue(updatedValue);

        if (onUpdate != null && updatedValue !== currentValue) {
            onUpdate(updatedValue !== '' ? updatedValue : null);
        }
    }

    let field = (
        <select id={id}
                name={name}
                value={currentValue ?? ''}
                onChange={changeHandler}
                disabled={items == null && groupedItems == null}
                className={inputClass}
        >
            {(items == null && groupedItems == null) ? (
                <option value=''>{emptyValue}</option>
            ) : (
                <>
                    {noneLabel != null ? (
                        <option value=''>{noneLabel}</option>
                    ) : null}
                    {items != null ? items.map(({key, value}) => (
                        <option key={key} value={key}>{value}</option>
                    )) : null}
                    {groupedItems != null ? groupedItems.map(({label, items}, index) => (
                        <optgroup key={index} label={label}>
                            {items.map(({key, value}) => (
                                <option key={key} value={key}>{value}</option>
                            ))}
                        </optgroup>
                    )) : null}
                </>
            )}
        </select>
    );

    if (label != null) {
        field = (
            <label>
                <div>{label}</div>
                {field}
            </label>
        );
    }

    return (
        <div className='field'>
            {field}
        </div>
    );

};

export default DropDownField;
