import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";

type TextInputFieldProps = {
    id?: string,
    name?: string,
    value?: string,
    label?: string,
    suffix?: string,
    disabled?: boolean,
    inputClass?: string,
    onUpdate?: (value: string) => void,
};

const TextInputField: FunctionComponent<TextInputFieldProps> = (props) => {

    const { id, name, value = '', label, suffix, disabled, inputClass, onUpdate } = props;

    const [ currentValue, setCurrentValue ] = useState<string>( value );

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const value = event.currentTarget.value;
        setCurrentValue(value);
    }

    useEffect(() => {
        setCurrentValue(value);
    }, [ value ]);

    function handleBlur() {
        if (onUpdate != null && currentValue !== value) {
            onUpdate(currentValue);
        }
    }

    let field = (
        <div>
            <input type="text"
                   id={id}
                   name={name}
                   disabled={disabled}
                   value={currentValue}
                   onChange={handleChange}
                   onBlur={handleBlur}
                   className={inputClass}
            />
            {suffix != null ? (
                <span>{suffix}</span>
            ) : null}
        </div>
    );
    if (label != null) {
        field = (
            <label>
                <div>{label}</div>
                {field}
            </label>
        );
    }

    return (
        <div className='field'>
            {field}
        </div>
    );

};

export default TextInputField;
