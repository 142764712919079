import { createContext, FunctionComponent, MouseEventHandler, useContext, useRef, useState } from "react";
import styled from "styled-components";

export type MessageLevel = 'debug' | 'info' | 'warn' | 'error';

export type Message = {
    level: MessageLevel;
    messageHtml: string;
};

type Props = {
};

function levelToLevelClassname(level: MessageLevel) {
    if (level === 'error') {
        return 'error';
    }
    if (level === 'warn') {
        return 'warn';
    }
    if (level === 'info') {
        return 'info';
    }
    return 'debug';
}

const MessagesWindowRoot = styled.div`
`;

const MessagesHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;
const MessagesHeaderLabel = styled.h3`
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.5rem;
    padding: 0 0.5rem;
    margin: 0;
`;

const MessagesWindow: FunctionComponent<Props> = (props) => {

    const messagesContext = useContext(MessagesContext);
    const { messages } = messagesContext;

    const [ showAll, setShowAll ] = useState( false );

    const onClickShowMoreButton: MouseEventHandler = (e) => {
        e.preventDefault();
        setShowAll( !showAll );
    };

    return (
        <MessagesWindowRoot data-testid='messages-window'>
            <MessagesHeader>
                <MessagesHeaderLabel>Messages:</MessagesHeaderLabel>
                <ShowMoreButton showAll={showAll}
                                onClick={onClickShowMoreButton}
                />
            </MessagesHeader>
            <MessagesOutput showAll={showAll}
                            messages={messages}
            />
        </MessagesWindowRoot>
    );

};

const ShowMoreButtonRoot = styled.button`
    font-size: 1.2rem;
    border-radius: 0.25rem;
    cursor: pointer;
`;

type ShowMoreButtonProps = {
    showAll: boolean,
    onClick: MouseEventHandler,
};

const ShowMoreButton: FunctionComponent<ShowMoreButtonProps> = (props) => {
    const { onClick, showAll } = props;
    return (
        <ShowMoreButtonRoot onClick={onClick}>
            {showAll ? "Show Less" : "Show More"}
        </ShowMoreButtonRoot>
    );
};

const MessagesOutputRoot = styled.div<{ showAll?: boolean }>`
    width: 100%;
    border: 2px solid #99f;
    background: #003060;
    font-family: monospace;
    padding: 0.5rem 1rem;

    .line {
        font-size: 1.1rem;
        line-height: 1.2rem;
    }
    ${props => !props.showAll ? `
        .line {
            &.debug, &.warn {
                display: none;
            }
        }
    ` : null}
    .debug {
        color: #7af;
    }
    .info {
        color: lightgray;
    }
    .warn {
        color: yellow;
    }
    .error {
        color: red;
    }
    a, a:hover, a:focus, a:visited {
        color: #88f;
    }
`;

type MessagesOutputProps = {
    showAll?: boolean,
    messages: Message[],
};

export const MessagesOutput: FunctionComponent<MessagesOutputProps> = (props) => {
    const { showAll, messages } = props;
    return (
        <MessagesOutputRoot showAll={showAll}>
            {messages.map((message, i) => (
                <div key={i}
                     className={'line ' + levelToLevelClassname(message.level)}
                     dangerouslySetInnerHTML={{__html: message.messageHtml}}
                />
            ))}
        </MessagesOutputRoot>

    );
};

export default MessagesWindow;

type MessageLogMethod = (messageHtml: string) => void;

type MessagesContextType = {
    messages: Message[],
    debug: MessageLogMethod,
    info: MessageLogMethod,
    warn: MessageLogMethod,
    error: MessageLogMethod,
    addMessage: ( level: MessageLevel, messageHtml: string ) => void,
};

export const MessagesContext = createContext<MessagesContextType>({
    debug(messageHtml: string): void {
    },
    error(messageHtml: string): void {
    },
    info(messageHtml: string): void {
    },
    warn(messageHtml: string): void {
    },
    addMessage(level: MessageLevel, messageHtml: string ): void {
    },
    messages: [],
});

type MessagesContextProps = {
    initialMessages?: Message[],
};

export const MessagesContextProvider: FunctionComponent<MessagesContextProps> = (props) => {

    const {
        initialMessages = [],
        children,
    } = props;

    const messages = useRef( [...initialMessages] );

    const [ , setState ] = useState<unknown>();

    function addMessage(level: MessageLevel, messageHtml: string) {
        messages.current.push({
            level,
            messageHtml,
        });
        setState({});
    }

    const contextValue: MessagesContextType = {
        debug(messageHtml: string): void {
            addMessage( 'debug', messageHtml );
        },
        info(messageHtml: string): void {
            addMessage( 'info', messageHtml );
        },
        warn(messageHtml: string): void {
            addMessage( 'warn', messageHtml );
        },
        error(messageHtml: string): void {
            addMessage( 'error', messageHtml );
        },
        addMessage( level: MessageLevel, messageHtml: string ) {
            addMessage( level, messageHtml );
        },
        messages: messages.current,
    };

    return (
        <MessagesContext.Provider value={contextValue}>
            {children}
        </MessagesContext.Provider>
    );
}
