import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from "styled-components";
import LoadingPage from "./screens/LoadingPage";
import LoginPage from "./screens/LoginPage";
import MainPage from "./screens/MainPage";
import { AuthClient, initAuth } from "./utils/auth";
import { MessagesContextProvider } from "./components/MessagesWindow";
import { DropDownItems } from "./components/DropDownField";
import { useMounted } from "./utils/testTools";
import { listPackages } from "./utils/packagesList";

type Props = {
    clientId: string,
};

const AppRoot = styled.div`
`;

const AppTitle = styled.h1`
    font-size: 1.8rem;
    line-height: 2rem;
    padding: 1rem;
    background-color: #303f9f;
    color: white;
    margin: 0;
    
    sup {
        font-size: 1rem;
    }
`;

export const App: FunctionComponent<Props> = (props) => {

    const {
        clientId
    } = props;

    const mounted = useMounted();

    const [ isLoggedIn, setIsLoggedIn ] = useState<boolean | null>(null);
    const [ auth, setAuth ] = useState<AuthClient | null>(null);

    useEffect(() => {
        initAuth(clientId, auth => {
            auth.on('change', ({value}) => {
                setAuth(auth);
                setIsLoggedIn(value);
            });
        });
    }, [ clientId ]);

    const [ packagesList, setPackagesList ] = useState<DropDownItems | undefined>(undefined);
    useEffect(() => {
        async function loadPackagesList() {
            let packagesList: DropDownItems = [];

            const list = await listPackages();
            if (list != null) {
                packagesList = list.map(item => {
                    return {
                        key: item.key,
                        value: item.label,
                    };
                });
            }

            if (mounted.current) {
                setPackagesList(packagesList);
            }
        }
        loadPackagesList();
    }, []);

    return (
        <MessagesContextProvider>
            <AppRoot>
                <AppTitle>
                    <span className="section">
                        Client Configuration Console 2.0 <sup>BETA</sup>
                    </span>
                </AppTitle>
                <div className="section">
                    {isLoggedIn === true ? (
                        <MainPage packagesList={packagesList} />
                    ) : isLoggedIn === false ? (
                        <LoginPage auth={auth} />
                    ) : (
                        <LoadingPage />
                    )}
                </div>
            </AppRoot>
        </MessagesContextProvider>
    );
}

export default App;
